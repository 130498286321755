import clipboardText from './clipboardText.svg';
import clipboardTextPurple from './clipboardTextPurple.svg';
import noteWhite from './noteWhite.svg';
import instructionsNotepadPurple from './instructionsNotepadPurple.svg';
import cross from './cross.svg';
import timerPause from './timerPause.svg';
import radioSelected from './radioSelected.svg';
import radioUnselected from './radioUnselected.svg';
import checkBoxUnticked from './checkboxUnticked.svg';
import checkboxTicked from './checkboxTicked.svg';
import infoPurpleFill from './infoPurpleFill.svg';
import avatar from './avatar.svg';
import chevronBlue from './chevronBlue.svg';
import timerGreen from './HomeScreen/timerGreen.svg';
import clipBoardTickPurple from './HomeScreen/clipBoardTickPurple.svg';
import checkToast from './checkToast.svg';
import crossToast from './crossToast.svg';
import tickGreen from './tickGreen.svg';
import crossRed from './crossRed.svg';
import archiveBook from './Login/archiveBook.svg';
import documentCopy from './Login/documentCopy.svg';
import presentationChart from './Login/presentationChart.svg';
import userHexagon from './Login/userHexagon.svg';
import chevronGrayBold from './chevronGrayBold.svg';
import logoutIcon from './logoutIcon.svg';
import infoPurpleOutline from './infoPurpleOutline.svg';
import arrowLeft from './arrowLeft.svg';
import sparkle from './sparkle.svg';
import lock from './lock.svg';
import meterEasyDifficulty from './meterEasyDifficulty.svg';
import meterMediumDifficulty from './meterMediumDifficulty.svg';
import meterHardDifficulty from './meterHardDifficulty.svg';
import trophyBlack from './trophyBlack.svg';
import taskSquareGray from './taskSquareGray.svg';
import barGraphGreen from './barGraphGreen.svg';
import chevronGray from './chevronGray.svg';
import timerPauseRed from './timerPauseRed.svg';
import timesUp from './timesUp.svg';
import moderateIcon from './moderateIcon.svg';
import filter from './filter.svg';
import chevronBlack from './chevronBlack.svg';
import documentText from './documentText.svg';
import timerPauseNoOutline from './timerPauseNoOutline.svg';
import clipBoardRed from './HomeScreen/clipBoardRed.svg';
import takeFullDiagBannerBG from './takeFullDiagBannerBG.svg';
import trendUp from './trendUp.svg';
import calendarBlack from './calendarBlack.svg';
import infoGrayOutline from './infoGrayOutline.svg';
import priorityPlanner from './priorityPlanner.svg';
import calendarGray from './calendarGray.svg';
import edit from './edit.svg';
import noteBgWhite from './noteBgWhite.svg';
import calendarPurple from './calendarPurple.svg';
import statusUpBgWhite from './statusUpBgWhite.svg';
import radioButtonGrey from './radioButtonGrey.svg';
import radioButtonSelectedBlack from './radioButtonSelectedBlack.svg';
import crossBold from './crossBold.svg';
import statusUp from './statusUp.svg';
import checkBubblePurple from './checkBubblePurple.svg';
import checkBubbleGrey from './checkBubbleGrey.svg';
import checkHexagon from './checkHexagon.svg';
import lockBgWhite from './lockBgWhite.svg';
import lockGrey from './lockGrey.svg';
import lockPurple from './lockPurple.svg';
import paymentDone from './paymentDone.svg';
import paymentPending from './paymentPending.svg';
import paymentFailed from './paymentFailed.svg';
import trendUpPurpleBg from './trendUpPurpleBg.svg';
import premiumIcon from './premiumIcon.svg';
import support from './support.svg';
import profileIcon from './profileIcon.svg';
import discountPercentage from './discountPercentage.svg';
import copyIconBlack from './copyIconBlack.svg';
import videoIcon from './videoIcon.svg';
import infoRedWhite from './infoRedWhite.svg';
import infoRedFill from './infoRedFill.svg';
import lightBulbGreen from './lightBulbGreen.svg';
import lightBulbTransparent from './lightBulbTransparent.svg';
import circularPurpleCheck from './circularPurpleCheck.svg';
import plusIcon from './plusIcon.svg';
import minusIcon from './minusIcon.svg';
import yourReward from './yourReward.svg';
import friendsReward from './friendsReward.svg';
import phone from './phone.svg';
import trendUpGradientBg from './trendUpGradientBg.svg';
import wrappedGiftYellow from './wrappedGiftyellow.svg';
import wrappedGiftPurple from './wrappedGiftPurple.svg';
import blogIcon from './Navbar/blogIcon.svg';
import guideIcon from './Navbar/guideIcon.svg';
import learningDBIcon from './Navbar/learningDBIcon.svg';
import practiceDBIcon from './Navbar/practiceDBIcon.svg';
import referralPlanGreenArrow from './referralPlanGreenArrow.svg';
import plansPageBookCallIcon from './plansPageBookCallIcon.svg';
import curvedArrowBlack from './CurvedArrow/curvedArrowBlack.svg';
import curvedArrowWhite from './CurvedArrow/curvedArrowWhite.svg';
import curvedArrowPurple from './CurvedArrow/curvedArrowPurple.svg';
import starBulletPointGray from './starBulletPointGray.svg';
import goldenStars from './goldenStars.svg';
import crossWhite from './crossWhite.svg';
import trophyPurple from './trophyPurple.svg';
import bookPurpleOutline from './bookPurpleOutline.svg';
import layer from './layer.svg';
import starFourBlack from './starFourBlack.svg';
import volume from './volume.svg';
import vocabReport from './vocabReport.svg';
import videoPlayButtonWhite from './videoPlayButtonWhite.svg';
import checkBubbleWhite from './checkBubbleWhite.svg';
import whiteSaveIcon from './whiteSaveIcon.svg';
import curatedContent from './PricingPage/curatedContent.svg';
import practiceQuestions from './PricingPage/practiceQuestions.svg';
import liveClasses from './PricingPage/liveClasses.svg';
import personalisedPreparation from './PricingPage/personalisedPreparation.svg';
import prepRating from './PricingPage/prepRating.svg';
import customerSupport from './PricingPage/customerSupport.svg';
import blackPremiumStar from './blackPremiumStar.svg';
import arrowWhite from './arrowWhite.svg';
import recordingSymbol from './recordingSymbol.svg';
import hourglass from './hourglass.svg';
import starFourGreen from './starFourGreen.svg';
import copyIconYellow from './copyIconYellow.svg';
import crownOutlineWhite from './crownOutlineWhite.svg';
import expertIconGrey from './expertIconGrey.svg';
import whitePremiumStar from './whitePremiumStar.svg';
import downloadWhite from './downloadWhite.svg';
import starPurpleCircle from './starPurpleCircle.svg';
import guidesOutlineBlack from './guidesOutlineBlack.svg';
import blogsOutlineBlack from './blogsOutlineBlack.svg';
import liveClassNavIcon from './Navbar/liveClassNavIcon.svg';
import onDemandNavIcon from './Navbar/onDemandNavIcon.svg';
import freebiesIcon from './freebiesIcon.svg';
import contactOutlineOrange from './contactOutlineOrange.svg';
import handShake from './handShake.svg';
import purpleStarIcon from './purpleStarIcon.svg';
import halfPurpleStarIcon from './halfPurpleStarIcon.svg';
import crownOutlineYellow from './crownOutlineYellow.svg';
import videoCamOutlineGray from './videoCamOutlineGray.svg';
import taskSquarePurple from './taskSquarePurple.svg';
import noteOutlineGray from './noteOutlineGray.svg';
import premiumStarPurple from './premiumStarPurple.svg';
import bookPurple from './bookPurple.svg';
import videoSquareGreen from './videoSquareGreen.svg';
import downloadIconPurple from './downloadIconPurple.svg';
import dashedGrayGradientLine from './dashedGrayGradientLine.svg';
import dashedPurpleGradientLine from './dashedPurpleGradientLine.svg';

export {
    clipboardText,
    clipboardTextPurple,
    noteWhite,
    instructionsNotepadPurple,
    cross,
    timerPause,
    radioSelected,
    radioUnselected,
    checkBoxUnticked,
    checkboxTicked,
    infoPurpleFill,
    avatar,
    chevronBlue,
    timerGreen,
    clipBoardTickPurple,
    checkToast,
    crossToast,
    tickGreen,
    crossRed,
    archiveBook,
    documentCopy,
    userHexagon,
    presentationChart,
    chevronGrayBold,
    logoutIcon,
    infoPurpleOutline,
    arrowLeft,
    sparkle,
    lock,
    meterEasyDifficulty,
    meterMediumDifficulty,
    meterHardDifficulty,
    trophyBlack,
    taskSquareGray,
    barGraphGreen,
    chevronGray,
    timerPauseRed,
    timesUp,
    moderateIcon,
    filter,
    chevronBlack,
    documentText,
    timerPauseNoOutline,
    clipBoardRed,
    takeFullDiagBannerBG,
    trendUp,
    priorityPlanner,
    edit,
    noteBgWhite,
    calendarGray,
    calendarBlack,
    infoGrayOutline,
    calendarPurple,
    statusUpBgWhite,
    radioButtonGrey,
    radioButtonSelectedBlack,
    crossBold,
    statusUp,
    checkBubblePurple,
    checkBubbleGrey,
    checkHexagon,
    lockBgWhite,
    lockGrey,
    lockPurple,
    paymentDone,
    paymentPending,
    paymentFailed,
    trendUpPurpleBg,
    premiumIcon,
    support,
    profileIcon,
    discountPercentage,
    copyIconBlack,
    videoIcon,
    infoRedWhite,
    infoRedFill,
    lightBulbGreen,
    lightBulbTransparent,
    circularPurpleCheck,
    plusIcon,
    minusIcon,
    yourReward,
    friendsReward,
    phone,
    trendUpGradientBg,
    wrappedGiftYellow,
    wrappedGiftPurple,
    blogIcon,
    guideIcon,
    practiceDBIcon,
    learningDBIcon,
    referralPlanGreenArrow,
    plansPageBookCallIcon,
    curvedArrowBlack,
    curvedArrowWhite,
    curvedArrowPurple,
    starBulletPointGray,
    goldenStars,
    crossWhite,
    trophyPurple,
    bookPurpleOutline,
    layer,
    starFourBlack,
    volume,
    vocabReport,
    videoPlayButtonWhite,
    checkBubbleWhite,
    whiteSaveIcon,
    curatedContent,
    practiceQuestions,
    liveClasses,
    personalisedPreparation,
    prepRating,
    customerSupport,
    blackPremiumStar,
    arrowWhite,
    recordingSymbol,
    hourglass,
    starFourGreen,
    copyIconYellow,
    crownOutlineWhite,
    expertIconGrey,
    whitePremiumStar,
    downloadWhite,
    starPurpleCircle,
    guidesOutlineBlack,
    blogsOutlineBlack,
    liveClassNavIcon,
    onDemandNavIcon,
    freebiesIcon,
    contactOutlineOrange,
    handShake,
    purpleStarIcon,
    halfPurpleStarIcon,
    crownOutlineYellow,
    videoCamOutlineGray,
    noteOutlineGray,
    taskSquarePurple,
    premiumStarPurple,
    bookPurple,
    videoSquareGreen,
    downloadIconPurple,
    dashedGrayGradientLine,
    dashedPurpleGradientLine
};
